$sidebar-bg-color: rgb(13, 110, 253);
$sidebar-color: rgb(255, 255, 255);
$sidebar-width: 270px !default;
$sidebar-collapsed-width: 80px !default;
$highlight-color: rgb(255, 255, 255);
$submenu-bg-color: rgb(0, 191, 255);
$submenu-bg-color-collapsed: rgb(0, 191, 255);
$icon-bg-color: rgb(0, 191, 255);
$icon-size: 35px !default;
$submenu-indent: 24px !default;
$breakpoint-xs: 480px !default;
$breakpoint-sm: 576px !default;
$breakpoint-md: 768px !default;
$breakpoint-lg: 992px !default;
$breakpoint-xl: 1200px !default;
$breakpoint-xxl: 1600px !default;

@import "~react-pro-sidebar/dist/scss/styles.scss";
