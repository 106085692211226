.sidebar-menu-item {
	color: white;
	font-weight: bold;
}

.sidebar-menu-item:hover {
	background-color: rgb(135, 206, 250);
	padding: 5px;
	border-radius: 10px;
}

.sidebar-submenu {
	color: white;
	font-weight: bold;
}

.sidebar-submenu:hover {
	background-color: rgb(135, 206, 250);
	padding: 5px;
	border-radius: 10px;
}
