.card-title {
	font-size: 1.8vw;
}

.card-subtitle {
	font-size: 1.2vw;
}

.card-bg {
	position: relative;
}

@media (max-width: 918px) {
	.card-title {
		font-size: 25px;
	}
	.card-subtitle {
		font-size: 18px;
	}
}

@media (max-width: 418px) {
	.card-title {
		font-size: 7vw;
	}
	.card-subtitle {
		font-size: 5vw;
	}
}
