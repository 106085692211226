a:link {
	color: white;
	background-color: transparent;
	text-decoration: none;
}

a:visited {
	color: black;
	background-color: transparent;
	text-decoration: none;
}

a:hover {
	color: lightgray;
	background-color: transparent;
	text-decoration: underline lightgray;
}

a:active {
	color: darkgray;
	background-color: transparent;
	text-decoration: underline darkgray;
}
