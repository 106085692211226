.leaflet-container {
	height: 100%;
}

@media (max-width: 630px) {
	.card-title {
		font-size: 20px;
	}
	.card-subtitle {
		font-size: 15px;
	}
}

@media (max-width: 633px) {
	.size {
		width: 30px;
	}
	.profile-heading {
		font-size: 5vw;
	}
	.profile-title {
		font-size: 2.5vw;
	}
	.profile-subtitle {
		font-size: 1.5vw;
	}
	.review-margin {
		margin-left: 25px;
		margin-right: 25px;
		margin-bottom: 25px;
	}
	.review-title {
		font-size: 10px;
	}
	.card-subtitle {
		font-size: 2.5vw;
	}
}

@media (max-width: 480px) {
	.review-title {
		font-size: 5px;
	}
}

@media (max-width: 380px) {
	.review-margin {
		margin: 0px;
	}
	.card-title {
		font-size: 15px;
	}
	.card-number {
		font-size: 15px;
	}
}
