.margin-col-potrait {
	margin-top: 1.5rem;
	margin-right: 3rem;
}

@media (max-width: 921px) {
	.margin-col-potrait {
		margin-top: 1.5rem;
		margin-right: 3rem;
		margin-left: 3rem;
	}
}
